/******************* Sentry *******************/
// Import and configure Sentry for error reporting
import Raven from 'raven-js'

// We aren't using the more up-to-date Sentry SDK at https://github.com/getsentry/sentry-javascript/tree/master/packages/browser
// because the version of Sentry we're running is too old and only works with Raven.

// Django will have set up `window.sentry_dsn` and `window.sentry_release` for us;
// if it's not there, we won't be able to do Sentry reporting.
if (window.sentry_dsn) {
  Raven.config(window.sentry_dsn, {
    release: window.sentry_release
  }).install();
} else {
  console.error("window.sentry_dsn not defined - Sentry not enabled")
}


